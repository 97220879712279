<template>
  <div>
    <DesktopSystemHeader
      v-if="$root.windowWidth > 961"
      :theExpirationDate="theExpirationDate"
    />
    <MobileSystemHeader v-if="$root.windowWidth < 961" />
  </div>
</template>

<script>
import DesktopSystemHeader from "./SystemHeaderDesktop.vue";
import MobileSystemHeader from "./SystemHeaderMobile.vue";

export default {
  name: "SystemHeader",
  props: {
    theExpirationDate: String,
  },
  components: { DesktopSystemHeader, MobileSystemHeader },
};
</script>
